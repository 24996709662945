import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SelectedTop10Skeleton = () => {
    const numberOfSkeletons = 6;

    return (
        <>
            <ul>
                {Array.from({ length: numberOfSkeletons }).map((_, index) => (
                    <li key={index} style={{ width: '130px' }}>
                        <a>
                            <Skeleton circle height={40} width={40} />
                            <h6 className="name">
                                <Skeleton width={50} height={10} />
                            </h6>
                        </a>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default SelectedTop10Skeleton;
