export function formatUserName(fullName) {
    // Split the full name into an array of words
    const nameArray = fullName.split(' ');

    // Extract the first name (up to 10 characters)
    const firstName = nameArray[0].substring(0, 9);

    // Extract the first character of the last name and add a period
    let lastInitial = '';
    if(nameArray[1]){
        lastInitial = nameArray[1]?.charAt(0) + '.';
    }
     
    // Concatenate the first name and last initial
    const formattedName = `${firstName} ${lastInitial}`;

    return formattedName;
}