// import React, { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import axios from 'axios';

// const stripePromise = loadStripe('pk_live_51NpY5dBI5nC1v0mzcQsHKDfaAmuBqP0fubi3FSizYSeUkl4WxjBZ4Lc1EoRJp7fVZBWE67IxBj29UDWzp7kfnxqi00RZssPZhC'); // Replace with your Stripe publishable key

// const CheckoutForm = ({ productId, email }) => {
//     console.log('productId', productId, 'email', email);
//   const stripe = useStripe();
//   const elements = useElements();
//   const [loading, setLoading] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setLoading(true);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/create_intent`, { email, productId }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       console.log(response)
//       const payload = await stripe.confirmCardPayment(response.data.data.clientSecret, {
//         payment_method: {
//           card: elements.getElement(CardElement),
//           billing_details: { email },
//         },
//       });

//       if (payload.error) {
//         console.error(payload.error.message);
//       } else {
//         console.log('Payment successful', payload.paymentIntent);
//         // Handle post-payment success actions like updating the database
//       }
//     } catch (error) {
//       console.error('Error creating payment intent:', error);
//     }

//     setLoading(false);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe || loading}>
//         {loading ? 'Processing...' : 'Payment'}
//       </button>
//     </form>
//   );
// };

// const App = () => {
//   const [email, setEmail] = useState("test1@gmail.com");
//   const [productId, setProductId] = useState('prod_PuICzVg9Ef9xA3');

//   return (
//     <div>
//       <h1>Stripe Payment</h1>
//       {/* <label>
//         Select Product:
//         <select value={productId} onChange={(e) => setProductId(e.target.value)}>
//           <option value="prod_PuICzVg9Ef9xA3">Monthly Participating Member</option>
//           <option value="prod_PyhzjplhWPqICc">Annual Participating Member</option>
//         </select>
//       </label> */}
//       <Elements stripe={stripePromise}>
//         <CheckoutForm productId={productId} email={email} />
//       </Elements>
//     </div>
//   );
// };

// export default App;





// import React, { useState } from 'react';
// import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import axios from 'axios';

// const stripePromise = loadStripe('pk_test_51Jx5QISIrGC1nYuugJ1kfBUn1fhEcW30dfLPuNm2dzQQRx3wuI4l6aMVOl8Dc5vaftj9LfyjD9L5TKtyvyOVXMTm00d0MIFLLD'); // Replace with your Stripe publishable key

// const CheckoutForm = ({ productId, email }) => {
//   const [loading, setLoading] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setLoading(true);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/create-checkout-session`, { email, productId }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       console.log(response)

//       const stripe = await stripePromise;
//       const { error } = await stripe.redirectToCheckout({
//         sessionId: response.data.data.id,
//       });

//       if (error) {
//         console.error('Stripe error:', error);
//       }
//     } catch (error) {
//       console.error('Error creating checkout session:', error);
//     }

//     setLoading(false);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <button type="submit" disabled={loading}>
//         {loading ? 'Processing...' : 'Pay'}
//       </button>
//     </form>
//   );
// };

// const App = () => {
//   const [email, setEmail] = useState("test1@gmail.com");
//   const [productId, setProductId] = useState('prod_QaTX3ywcZY9cCX');
// //   prod_QaUTwu37Y4pfrY
//   return (
//     <div>
//       <h1>Stripe Payment</h1>
//       <Elements stripe={stripePromise}>
//         <CheckoutForm productId={productId} email={email} />
//       </Elements>
//     </div>
//   );
// };

// export default App;




import React, { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

const CheckoutForm = ({ sessionId }) => {
  const stripe = useStripe();

  useEffect(() => {
    const redirectToCheckout = async () => {
      if (stripe && sessionId) {
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
          console.error('Stripe error:', error);
        }
      }
    };

    redirectToCheckout();
  }, [stripe, sessionId]);

  return (
    <div>
      <p>Redirecting to checkout...</p>
    </div>
  );
};

export default CheckoutForm;
