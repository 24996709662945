import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ModalAction } from "../../Store/Modal-Slice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AES } from "crypto-js";
import { formatUserName } from "../../utils/FormattedName";
import SelectedTop10Sekeleton from "../Skeleton/SelectedTop10Sekeleton";

const LastWeekTop10 = (props) => {
  let [WeeklyTopTen, setWeeklyTopTen] = useState([]);
  let [HideWeeklyTopTen, setHideWeeklyTopTen] = useState(false);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  // const formattedUserName = formatUserName(fullName);

  let PhotoUploadModalHandler = () => {
    dispatch(ModalAction.setPhotoUploadModalState(true));
    dispatch(ModalAction.setPhotoUploadType("hunted_photo"));
    dispatch(ModalAction.setisQueued({ queue: false }));
  };

  let OtherUserHandler = (event, userid) => {
    event.preventDefault();

    if (props.userid == userid) {
      navigate("/profile");
    } else {
      let userData = {
        PhotoID: "",
        UserID: userid,
      };
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const encryptedUserData = AES.encrypt(
        JSON.stringify(userData),
        secretKey
      ).toString();
      navigate(
        `/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`
      );
    }

    window.scrollTo(0, 0);
  };
  useEffect(() => {
    let LoadPhotos = async function () {
      try {
        let headers = {
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
        };

        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/weeklyTopTenUsers`,
          {},
          { headers }
        );

        setWeeklyTopTen(response.data.data);
        if (response.data.data.length == 0) {
          setHideWeeklyTopTen(true);
        }
      } catch (error) {}
    };
    LoadPhotos();
  }, []);
  return (
    <>
      {!HideWeeklyTopTen && (
        <section className="last-weeks-top-section">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 col-md-12">
                <div className="last_weeks_top_content">
                  <div className="page_header text-center">
                    <h3 className="title">
                      Congratulations to last week's top ten:
                    </h3>
                    <h5 className="subtitle">Selected by your Upvotes</h5>
                  </div>
                  <div className="selected_users">
                    {WeeklyTopTen.length > 0 ? (
                      <ul>
                        {WeeklyTopTen.map((data) => (
                          <li key={data.user_id} style={{ width: "130px" }}>
                            <a
                              onClick={(event) =>
                                OtherUserHandler(event, data.user_id)
                              }
                            >
                              <img
                                src={
                                  data.user_photo_url
                                    ? data.user_photo_url
                                    : `https://ui-avatars.com/api/?name=${data.name}&size=40&rounded=true&background=01655a&color=fff`
                                }
                                alt="img"
                              />
                              {/* Before md */}
                              <h6 className="name nameOnMobile">
                                {formatUserName(data.name)}
                              </h6>

                              {/* After md */}
                              <h6 className="name nameOnDestop ">
                                {data.name.length > 13
                                  ? data.name.slice(0, 11) + ".."
                                  : data.name}
                              </h6>
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <SelectedTop10Sekeleton />
                    )}
                    <div className="buttons">
                      <button className="btn" onClick={PhotoUploadModalHandler}>
                        Include Your Photo
                      </button>
                      <Link to={"/topten"} className="btn btn-edit">
                        Weekly Top 10
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LastWeekTop10;
