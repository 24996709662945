import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { performVote } from '../../utils/API/UpVoteApi';
import { ShortAddress } from '../../utils/Address';
import { formatDate } from "../../utils/Formatteddate"
import amplitude from 'amplitude-js';
import { followUser } from '../../utils/API/FollowApi';
import { useNavigate } from 'react-router-dom';
import { AES } from 'crypto-js';
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { formatLikes } from '../../utils/FomattedLike';
import { LightBoxAction } from '../../Store/LightboxSlice';


const LightBoxList = (props) => {
    let [votebtnactive, setvotebtnactive] = useState(props.is_vote)
    let [upDownvote, setupDownvote] = useState(props.is_vote)
    let [follow, setfollow] = useState(props.is_follow)
    let [TotalVote, setTotalVote] = useState(props.TotalVotes)
    let address = ShortAddress(props.Userlocation, 12);
    const formattedDate = formatDate(props.postedDate);


    // //// Like////
    let formattedLikes
    if (TotalVote) {
        formattedLikes = formatLikes(TotalVote);
    }



    let navigate = useNavigate();


    let NavigatorHandler = (event) => {
        event.preventDefault();
        if (props.Userid == props.LoginUserid) {
            navigate("/profile");
            dispatch(ModalAction.setisLightboxModalOpen(false))
        } else {

            let userData = {
                PhotoID: "",
                UserID: props.Userid,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
            dispatch(ModalAction.setisLightboxModalOpen(false))
            window.scrollTo(0, 0)

        }
    }

    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.Userid))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    let voteHandler = async (event) => {
        event.preventDefault();
        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)




        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
            dispatch(LightBoxAction.setUpdateTheMianFeedpage({ TotalVote: (TotalVote * 1) - 1, Follow: follow, photoID: props.photoId, votebtnactive: !votebtnactive }))
        } else {
            dispatch(LightBoxAction.setUpdateTheMianFeedpage({ TotalVote: (TotalVote * 1) + 1, Follow: follow, photoID: props.photoId, votebtnactive: !votebtnactive }))
            up_or_down_vote = "add"
            amplitude.getInstance().setUserId(props.LoginUserid);
            amplitude.getInstance().logEvent('Upvote', { UserID: props.LoginUserid });
            // SendNotificationFn()
        }
        try {
            const response = await performVote(props.photoId, up_or_down_vote, props.usertoken);

            setTotalVote(response.data.data.voteCount)
        } catch (error) {

        }

    }


    let FollowHandler = async (event) => {
        event.preventDefault();
        setfollow(!follow)
        console.log(follow, '............>')

        dispatch(LightBoxAction.setUpdateTheMianFeedpage({ TotalVote, Follow: !follow, photoID: props.photoId }))
        try {
            const response = await followUser(props.Userid, !follow, props.usertoken, props.LoginUserid, SendNotificationFn);
        } catch (error) {
        }
    }


    let SharePhotobtnHandler = () => {
        dispatch(ModalAction.setisLightboxModalOpen(false))
        dispatch(ModalAction.setSharePhotoModalState(true))

        dispatch(ModalAction.setShareModalPhotoInfo(
            props.LoginUserid !== props.Userid ?
                {
                    "path": props.photo_url,
                    "id": props.photoId,
                    "shareType": "Share Other User Photo"
                }
                :
                {
                    "path": props.photo_url,
                    "id": props.photoId,
                    "shareType": ""
                }
        ))
    }



    let UpdateTheMianFeedpage = useSelector((state) => state.LightBox.UpdateTheMianFeedpage);


    useEffect(() => {

        if (UpdateTheMianFeedpage?.photoID !== props.photoId) {
            return
        } else {

            console.log(UpdateTheMianFeedpage, 'UpdateTheMianFeedpage')
            if (UpdateTheMianFeedpage.TotalVote !== TotalVote) {
                setTotalVote(UpdateTheMianFeedpage.TotalVote)
                setvotebtnactive(UpdateTheMianFeedpage.votebtnactive)
                setupDownvote(UpdateTheMianFeedpage.votebtnactive)
            }
            setfollow(UpdateTheMianFeedpage?.Follow)

        }

    }, []);
    return (
        <>




            {/* <div className="gallery_item_inner">
                <div className="gallery_img">
                    {!props.gallery && <ul className="feed-list">
                        <li>
                            <a style={{ color: 'white' }} onClick={SharePhotobtnHandler}>Share <img src="./assets/img/icons/share-icon.svg" alt className="img-fluid share-img" /></a>
                        </li>
                    </ul>}
                    <img className="img-responsive" src={props.photo_url} alt />
                    {!props.gallery && <div className="feed_vote box-footer" style={{ position: "absolute", right: "0", bottom: '0', margin: '20px' }}>

                        <Link
                            onClick={voteHandler}
                            id='PhotoLikeBtn.'
                            className={`btn btn-vote ${votebtnactive ? "active" : ""}`}>
                            <img src="./assets/img/icons/arrow-up.svg" alt="img" />
                            <span>{formattedLikes}</span>

                        </Link>
                    </div>}
                </div>

                <div className="gallery_content_meta">
                    {!props.LoginUserPhotos && <>
                        <div className="feed_user">
                            <a
                                className='user_img'
                                onClick={NavigatorHandler}
                                style={{
                                    backgroundImage: `url(${props.UserPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    height: '40px',
                                    width: '40px',
                                }}>
                                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} />}
                            </a>
                            <div className="user_text">
                                <a onClick={NavigatorHandler} className="user_name">{props.Name}</a>
                                <p className="user_meta">
                                    <span className="location">{address}</span>
                                    <span className="date">{formattedDate}</span>
                                </p>
                            </div>
                        </div>
                        {props.LoginUserid !== props.Userid && <a className="btn btn-follow follow" onClick={FollowHandler}>
                            <img src={`${!follow ? "./assets/img/icons/follow-black.svg" : "./assets/img/icons/following.svg"}`} alt='img' /><span>{`${!follow ? "Follow" : "Following"}`}</span>
                        </a>}

                    </>}
                </div>


            </div> */}

            <div className="gallery_item_inner" >
                <div className="gallery_img">
                    <ul className="feed-list d-none d-md-flex">
                        <li>
                            <a onClick={SharePhotobtnHandler} style={{ color: 'white' }}>Share <img src="./assets/img/icons/share-icon.svg" alt className="img-fluid share-img" /></a>
                        </li>
                    </ul>
                    <img className="img-responsive" src={props.photo_url} alt style={{ cursor: 'grab' }} />
                </div>
                {/* meta */}
                <div className="gallery_content_meta">
                    {!props.LoginUserPhotos && <>
                        <div className="feed_user">
                            <a
                                className='user_img'
                                onClick={NavigatorHandler}
                                style={{
                                    backgroundImage: `url(${props.UserPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    height: '40px',
                                    width: '40px',
                                }}>
                                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} />}
                            </a>
                            <div className="user_text">
                                <a onClick={NavigatorHandler} className="user_name">{props.Name}</a>
                                <p className="user_meta">
                                    <span className="location">{address}</span>
                                    <span className="date">{formattedDate}</span>
                                </p>
                            </div>
                        </div>

                        <a className="share-img d-md-none" onClick={SharePhotobtnHandler}>
                            <img src="./assets/img/icons/share-icon-black.svg" alt />
                        </a>
                        <a className="btn btn-follow follow d-md-none" onClick={FollowHandler}>
                            <img style={{ filter: "invert(1)" }} src={`./assets/img/icons/${!follow ? "follow.svg" : "user-minus.svg"}`} alt />
                        </a>
                        <div className="feed_vote">


                            <a style={{ border: "solid 1px #cdced2" }} className={`btn btn-vote ${votebtnactive ? "active" : ""}`} onClick={voteHandler}>

                                <img src="./assets/img/icons/arrow-up.svg" alt />
                                <span className="d-none d-md-inline-block">{votebtnactive ? "Upvoted" : "Upvote"}</span>
                                <span className="d-md-none">{formattedLikes}</span>
                            </a>
                        </div>
                    </>}



                    {props.LoginUserid !== props.Userid && <a onClick={FollowHandler} className="btn btn-follow follow d-none d-md-inline-flex">
                        <img src={`${!follow ? "./assets/img/icons/follow-black.svg" : "./assets/img/icons/following.svg"}`} alt='img' /><span>{`${!follow ? "Follow" : "Following"}`}</span>
                    </a>}
                </div>
            </div>


        </>
    )
}

export default LightBoxList