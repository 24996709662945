import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { GalleryAction } from '../../Store/Gallery-Slice';
import * as exifr from 'exifr';
import { useSelector } from 'react-redux';
import amplitude from 'amplitude-js';
import { checkImageLimit } from '../../utils/API/GalleryApi';
import { ModalAction } from '../../Store/Modal-Slice';


const GalleryPhotoUpload = ({ usertoken, CheckPhotoListIsEmptyFn, UserPaymentStatus }) => {
    const [images, setImages] = useState([]);
    const [DisplayImages, setDisplayImages] = useState([]);
    const [UploadPersentage, setUploadPersentage] = useState('');
    const [UploadedProgressState, setUploadedProgressState] = useState(false);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [totalUploadedMB, setTotalUploadedMB] = useState(0);
    const [totalMBBeingUploaded, setTotalMBBeingUploaded] = useState(0);
    const [ERROR, setERROR] = useState('')
    const [loading, setLoading] = useState(false);
    const [LimitError, setLimitError] = useState('');
    const [CheckUserCanUploadPhoto, setCheckUserCanUploadPhoto] = useState(false);

    let dispatch = useDispatch()

    let GalleryUploadComponentState = useSelector(state => state.Gallery.GalleryUploadComponentState)


    let openLimitModalHandler = () => {
        dispatch(ModalAction.setPopupModalMessage({

            popupHeading: "Oops! Upgrade to upload.",
            popupMessage: "Your 1st five profile gallery photos are free. After that, upload as many as 50 photos by upgrading to our paid membership.",
            popupMessage2: 'So you don’t lose the photo(s) you just uploaded, we’ll hold it for you for 12 hours. Upgrade before then to automatically add to your gallery.'

        }))
        dispatch(ModalAction.setPopupModalState(true))
    }

    const MaxLimit = 200;
    let AlReadyUploadPhotoCount;
    const CheckingImageLimit = async (selectedImages, imagesArray, totalSizeMB) => {
        setLoading(true);  // Show loading indicator
        setCheckUserCanUploadPhoto(false);
        setUploadedProgressState(true);
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: false }));
        setLimitError("")
        try {
            const payload = {
                "isGallery": true,
            };

            const response = await checkImageLimit(payload, usertoken);
            console.log(response);
            AlReadyUploadPhotoCount = response.data.data.photo_count;
            let remainingPhoto = MaxLimit - AlReadyUploadPhotoCount;

            if (remainingPhoto >= imagesArray.length) {
                setCheckUserCanUploadPhoto(true);
                uploadHandler(selectedImages, imagesArray, totalSizeMB);
            } else {
                if (remainingPhoto > 0) {
                    setLimitError(`You have already uploaded ${AlReadyUploadPhotoCount} photos. You can upload ${remainingPhoto} more photos. Maximum limit: 200 photos.`);
                } else {
                    setLimitError(`You have already uploaded ${AlReadyUploadPhotoCount} photos. You have reached the maximum limit of 200 photos. Please remove some photos to upload new ones.`);
                }
            }


            console.log(response);
        } catch (error) {
            console.log(error);
            setLimitError('Error checking image limit.');
        } finally {
            setLoading(false);  // Hide loading indicator
        }
    };

    const imgHandler = async (e) => {
        setERROR('');
        const selectedImages = e.target.files;

        const invalidImages = [];
        const oversizedImages = [];
        const largeEdgesImages = [];

        const loadImage = (image) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;
                    const maxEdgeSize = 6500;

                    if (width > maxEdgeSize || height > maxEdgeSize) {
                        largeEdgesImages.push(image);
                    }
                    resolve();
                };
                img.src = URL.createObjectURL(image);
            });
        };

        for (let i = 0; i < selectedImages.length; i++) {
            const image = selectedImages[i];

            if (!['image/jpeg', 'image/png'].includes(image.type)) {
                invalidImages.push(image);
                continue;
            }

            if (image.size > 50 * 1024 * 1024) {
                oversizedImages.push(image);
                continue;
            }

            await loadImage(image);
        }

        if (invalidImages.length > 0) {
            const invalidFileNames = invalidImages.map(image => image.name).join(', ');
            setERROR(`File name "${invalidFileNames}" is not a PNG or JPG image.`);
            return;
        }

        if (oversizedImages.length > 0) {
            const oversizedFileNames = oversizedImages.map(image => image.name).join(', ');
            setERROR(`File name "${oversizedFileNames}" exceeds the maximum size limit of 50MB.`);
            return;
        }

        if (largeEdgesImages.length > 0) {
            const largeEdgesFileNames = largeEdgesImages.map(image => image.name).join(', ');
            setERROR(`Edges of the photo in file "${largeEdgesFileNames}" exceed 6500 pixels.`);
            return;
        }


        setImages(selectedImages);
        const imagesArray = [];
        let totalSize = 0;
        const currentDate = new Date().toISOString();

        for (let i = 0; i < selectedImages.length; i++) {
            const file = selectedImages[i];

            imagesArray.push({
                url: URL.createObjectURL(file),
                createdAt: currentDate
            });
            totalSize += file.size;
        }
        const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2);
        setTotalUploadedMB(totalSizeMB);
        setDisplayImages(imagesArray);

        await CheckingImageLimit(selectedImages, imagesArray, totalSizeMB);


        // if (CheckUserCanUploadPhoto) {
        //     uploadHandler(selectedImages, imagesArray, totalSizeMB);
        // }
        // uploadHandler(selectedImages, imagesArray, totalSizeMB);
    };


    let UploadedGalleryPhotos = useSelector(state => state.Gallery.SuccessfullyUploadedGalleryPhotos)
    let SuccessfullyManageGalleryPhotosArray = useSelector(state => state.Gallery.SuccessfullyManageGalleryPhotos)



    const uploadHandler = async (selectedImages, displayImages, totalFileSizeMB) => {




        try {
            const timestamp = new Date().getTime();
            let uploadProgress = [];
            const imagesArray = Array.from(selectedImages);
            const totalSizeMB = imagesArray.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024);
            setTotalUploadedMB(totalSizeMB.toFixed(2));

            const uploadPromises = imagesArray.map((file, index) => {
                return new Promise(async (resolve, reject) => {
                    const filename = `image_${timestamp}_${index}.jpg`;
                    let formData = new FormData();
                    formData.append('photoUrl', file, filename);

                    const metadata = await exifr.parse(file, {
                        tiff: true,
                        xmp: true,
                    });

                    if (metadata) {
                        const metadataObject = {
                            cameraName: metadata.Make,
                            focalLength: metadata.FocalLength,
                            lens: metadata.LensModel || metadata.Lens,
                            aperture: metadata.FNumber,
                            iso: metadata.ISO,
                            shutterSpeed: metadata.ExposureTime,
                            dateTaken: metadata?.DateTimeOriginal,
                        };

                        const metadataString = JSON.stringify(metadataObject);

                        formData.append('metadata', metadataString);
                    }

                    const fileSizeMB = file.size / (1024 * 1024);
                    uploadProgress.push({
                        url: filename,
                        size: fileSizeMB,
                        percentage: 0
                    });

                    const headers = {
                        'Authorization': `Bearer ${usertoken}`
                    };

                    axios.post(`${process.env.REACT_APP_BASE_URL}/photo/add-gallery`, formData, {
                        headers,
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            uploadProgress[index].percentage = percentCompleted;

                            // Update upload progress state
                            setUploadPersentage([...uploadProgress]);
                            setUploadedProgressState(true);

                            // Calculate real-time values
                            const elapsedTime = (new Date().getTime() - timestamp) / 1000; // in seconds
                            const uploadedBytes = uploadProgress.reduce((acc, curr) => acc + (curr.percentage / 100) * curr.size * 1024 * 1024, 0);
                            const speed = (uploadedBytes / elapsedTime) * 0.000008; // Bytes per second to Mbit/s
                            const percentage = uploadProgress.reduce((acc, curr) => acc + curr.percentage, 0) / uploadProgress.length;
                            const uploadedMB = totalSizeMB * (percentage / 100);

                            setUploadSpeed(speed.toFixed(2));
                            setTotalPercentage(percentage.toFixed(2));
                            setTotalMBBeingUploaded(uploadedMB.toFixed(2));
                        }
                    })
                        .then(() => {
                            uploadProgress[index].percentage = 100;
                            setUploadPersentage([...uploadProgress]);
                            resolve();
                        })
                        .catch(error => {
                            console.log('Error uploading:', error);
                            reject(error);
                        });
                });
            });

            await Promise.all(uploadPromises);

            setTotalPercentage(100.00)
            setTotalMBBeingUploaded(totalFileSizeMB)

            // if (UploadedGalleryPhotos.length < 5) {
            //     dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos([...SuccessfullyManageGalleryPhotosArray, ...displayImages, ...UploadedGalleryPhotos]));
            // } else {
            const uniquePhotos = [
                ...new Set([
                    ...SuccessfullyManageGalleryPhotosArray,
                    ...UploadedGalleryPhotos,
                    ...displayImages
                ])
            ];
            CheckPhotoListIsEmptyFn(false)
            dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos(uniquePhotos));

            if (!UserPaymentStatus && (AlReadyUploadPhotoCount + imagesArray.length) > 5) {
                openLimitModalHandler()
            }
            for (let i = 0; i < displayImages; i++) {
                amplitude.getInstance().logEvent("Gallery Photo Add");
            }
            // }

        } catch (error) {
            console.log('Error uploading:', error);
            setERROR('Upload failed. Please try again.');
        }
    };





    return (
        <>
            <div className="gallery_photo_upload">
                {!UploadedProgressState || GalleryUploadComponentState.BtnDisable ?

                    (
                        <div>
                            {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}
                            <div className="gallery_photo_upload_header">
                                <h2 className="title"><img src="./assets/img/icons/upload.svg" alt='Img' />Upload</h2>
                                <p className="subtitle">Add photos to your Personal Gallery here.</p>
                            </div>
                            <div className="file-upload">
                                <div className="image-upload-wrap">
                                    <input className="file-upload-input" type="file" onChange={imgHandler} multiple />
                                    <div className="drag-text">
                                        <h4 className="title">Drag and drop your photo here</h4>
                                        <span className="or">or</span>
                                        <button className="file-upload-btn btn" type="button">Upload from device</button>
                                        <span className="support_note">Max. 6500 x 6500 px., 50 MB <br /> JPB or PNG format only. No
                                            Borders. <br />
                                            Upload up to 20 photos at once.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                    :
                    (CheckUserCanUploadPhoto ? <div className="gallery_photo_upload uploading">
                        <div className="gallery_photo_upload_header">
                            <h2 className="title"><img src="./assets/img/icons/upload.svg" alt='img' />Upload</h2>
                            <p className="subtitle">Your photos are uploading.</p>
                        </div>
                        <div className="gallery_photo_uploading_wrap">
                            <span className="uploading_speed">{uploadSpeed} Mbit/s {totalPercentage}% {totalMBBeingUploaded} MB {totalUploadedMB} MB </span>
                            <ul className="uploading_list">
                                {UploadPersentage && UploadPersentage?.map((data, index) => (
                                    <li className="upload_item" key={index}>
                                        {data.percentage !== 100 ? <div className="spinner-border text-secondary" role="status" style={{ height: "25px", width: "25px" }}>
                                            <span className="sr-only"></span>
                                        </div>
                                            :
                                            <span className="done"><img src="./assets/img/icons/done.svg" alt='img' /></span>}
                                        <p className="file_name">{data.url}</p>
                                        <div className="progress_wrap" style={{ width: "500px" }}>
                                            <div className="progress-line">
                                                <div className="fill-progress" style={{ width: `${data.percentage}%` }} />
                                                <div className="empty-progress" />
                                            </div>
                                        </div>
                                        <span className="file_size">{data.size.toFixed(2)} MB</span>
                                    </li>))}
                            </ul>
                        </div>
                    </div>
                        :
                        (!LimitError ? <div className="d-flex justify-content-center flex-column align-items-center gap-3" style={{ margin: '170px auto' }}>
                            <span className="FollowModalImgLoader" style={{ display: "block" }}></span>
                            <p>Please Wait</p>
                        </div>
                            :
                            <div className="d-flex justify-content-center   gap-1">
                                <img src='./assets/img/icons/info.svg' style={{ height: "25px" }}></img>
                                <h5 style={{ marginBottom: "0px" }}>{LimitError}</h5>
                            </div>
                        ))
                }


            </div>

        </>
    );
}

export default GalleryPhotoUpload;
