import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { ModalAction } from '../../Store/Modal-Slice'
import { useDispatch } from 'react-redux'
import { testimonialsAPI } from '../../utils/API/TestimonialApi';
import { useSelector } from 'react-redux';
import { PayWallAction } from '../../Store/PayWallSlice';
import { smoothScrollToElement } from '../../utils/Scroll';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../Components/Strip';
import { decryptUserData } from '../../utils/encryptedData';
import PaymentBtn from '../Genaric/PaymentBtn';
import useCloseModalHandler from '../../Hooks/useCloseModal';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIP_PUBLISHABLE_KEY}`); // Replace with your Stripe publishable key

const PayWallModal = () => {


    const [email, setEmail] = useState(''); // Example email, you can update this as needed
    const [sessionId, setSessionId] = useState('');
    const [loading, setLoading] = useState(false);
    const [ActiveTabValue, setActiveTabValue] = useState("yearly");

    let navigate = useNavigate();

    useEffect(() => {
        // <---------------- decrypted data ------------->
        const encryptedUserData = localStorage.getItem('Data');
        const decryptedUserData = decryptUserData(encryptedUserData);
        let user_email = decryptedUserData.user_email
        setEmail(user_email ? user_email : '')

        if (currentUrl == "/pmupgrade") {
            amplitude.getInstance().logEvent('Paywall General Standalone')
        } else {
            amplitude.getInstance().logEvent('Paywall General')
        }
    }, [])

    const currentUrl = window.location.pathname;
    console.log(`${currentUrl + "?modal=paywall"}`);

    const handleButtonClick = async (productId) => {

        if (currentUrl == "/14dfspecial") {
            const monthlySubscriptionUrl = `https://buy.stripe.com/cN2eWvg20dmocmscMN?prefilled_email=${email}`;
            const annualSubscriptionUrl = `https://buy.stripe.com/fZe15F7vu0zCgCIcMO?prefilled_email=${email}`;

            window.location.href = ActiveTabValue == "monthly" ? monthlySubscriptionUrl : annualSubscriptionUrl;


        } else {
            try {
                setLoading(true);
                let payload = {
                    email,
                    productId,
                    successUrl: currentUrl,
                    cancelUrl: `${currentUrl + "?modal=paywall"}`

                }
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/create-checkout-session`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                setLoading(false);
                if (response.data && response.data.data && response.data.data.id) {
                    setSessionId(response.data.data.id);
                } else {
                    console.error('Error: Invalid response data');
                }
            } catch (error) {
                console.error('Error creating checkout session:', error);
                setLoading(false);
            }
        }


    };







    const [testimonialUserData, settestimonialUserData] = useState([]);

    let PayWallModalTitleObj = useSelector(state => state.PayWall.PayWallModalTitleObj)

    useEffect(() => {
        let testimonialsList = async () => {
            try {
                const response = await testimonialsAPI();
                settestimonialUserData(response.data.data)
            } catch (error) {

            }

        }
        testimonialsList()
    }, [])

    let dispatch = useDispatch()



    let SamCardSideBarOpenHandler = (ProductName) => {
        dispatch(PayWallAction.setSamCardProductName(ProductName))
        dispatch(PayWallAction.setisSamCardSideBarOpen(true))
        // dispatch(ModalAction.setisPayWallOpen(false))
    }


    // let ScrollHandler = (e) => {
    //     smoothScrollToElement(e, '#priceTable')
    // }
    const closeModal = () => {
        if (currentUrl == "/pmupgrade" || currentUrl == "/14dfspecial") {

            navigate('/')
        }



        dispatch(ModalAction.setisPayWallOpen(false))

    };
    const modalRef = useCloseModalHandler(closeModal);
    return (
        <>
            {sessionId && (
                <Elements stripe={stripePromise}>
                    <CheckoutForm sessionId={sessionId} />
                </Elements>
            )}

            <div className="backdrop" />

            <div className="modal fade bph-modal paywallModal show" id="paywallModal" tabIndex={-1} aria-labelledby="paywallModal" aria-hidden="true" style={{ display: "flex", zIndex: "53" }}>
                <div className="modal-dialog modalAnimation">
                    <div className="modal-content" ref={modalRef}>
                        <div className="modal-body">
                            <div className="paywall_modal_wrapper">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                    <img src="./assets/img/icons/close.svg" alt />
                                </button>
                                {/* hero */}
                                <div className="modal_hero" >
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_hero_container">
                                                    <div className="modal_hero_content">
                                                        <div className="ticker">
                                                            <img src="./assets/img/paywall/perticipating_ticker.png" alt />
                                                        </div>
                                                        <h1 className="title">Show more. See More. Do it first.</h1>
                                                        <div className="desc">Show more of your photos, view more of others’ photos, and do it first. On your schedule.</div>

                                                        <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Try It"} />
                                                    </div>
                                                    <div className="modal_hero_img">
                                                        <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall_model.png" alt />
                                                    </div>
                                                </div>
                                                <div className="modal_hero_bottom_text">
                                                    Bobby Bobertson, Chicago, IL USA | Weekly Top Ten Photo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* price */}
                                <div className="modal_price">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">{PayWallModalTitleObj.Title ? PayWallModalTitleObj.Title : "Upgrade to turbo boost your Big Photo Hunt"}</h2>
                                                    <div className="desc">{PayWallModalTitleObj.Subtitle ? PayWallModalTitleObj.Subtitle : "Become a Participating Member to be included in our exclusive contests, events and directories and get access to juiced up features that put you first."}</div>



                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={PayWallModalTitleObj.btnTest ? PayWallModalTitleObj.btnTest : "Join now"} />

                                                </div>
                                                <div className="modal_price_table" >
                                                    <ul className="price_table_tab" role="tablist">
                                                        <li className="tab_item" role="presentation">
                                                            <button onClick={() => { setActiveTabValue("yearly") }} className={`tab_item_link ${ActiveTabValue == "yearly" ? "active" : ''}`} data-bs-toggle="tab" data-bs-target="#tabYearly" type="button" role="tab" aria-selected="false">Yearly</button>
                                                        </li>
                                                        <li className="tab_item" role="presentation">
                                                            <button onClick={() => { setActiveTabValue("monthly") }} className={`tab_item_link ${ActiveTabValue == "monthly" ? "active" : ''}`} data-bs-toggle="tab" data-bs-target="#tabMonthly" type="button" role="tab" aria-selected="true">Monthly</button>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content price_table_content">
                                                        <div className={`tab-pane content_item fade ${ActiveTabValue == "yearly" ? "show active" : ''}`} id="tabYearly" role="tabpanel">
                                                            <div className="price_table_item_inner">
                                                                <div className="price_table_header">
                                                                    <h1 className="price">$7.49 <span className="period">per month <br />$89.88/year</span></h1>
                                                                    <h5 className="tagline">Boost your presence with new in depth tools</h5>
                                                                </div>
                                                                <div className="price_table_feature">
                                                                    <h6 className="feature_title">What's Included:</h6>
                                                                    <ul>
                                                                        <li>Automatic monthly prize entry.</li>
                                                                        <li>Share up 50 portfolio gallery photos.</li>
                                                                        <li>Queue your photos up to four weeks ahead.</li>
                                                                        <li>View Camera Metadata and Settings </li>
                                                                        <li>Get Invites to all Monthly Townhalls</li>
                                                                        <li>Access to our Participating Member directory.</li>
                                                                        <li>Get a Participating Member badge.</li>
                                                                        <li>Post on your Followers' feeds (soon)</li>
                                                                        <li>See the "Ken Loves You" Video 🤪</li>
                                                                        <li>Help keep this project going!</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="price_table_btn">
                                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I want to Participate"} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane content_item fade ${ActiveTabValue == "monthly" ? "show active" : ''}`} id="tabMonthly" role="tabpanel">
                                                            <div className="price_table_item_inner">
                                                                <div className="price_table_header">
                                                                    <h1 className="price">$12.99 <span className="period">per month</span></h1>
                                                                    <h5 className="tagline">Boost your presence with new in depth tools</h5>
                                                                </div>
                                                                <div className="price_table_feature">
                                                                    <h6 className="feature_title">What's Included:</h6>
                                                                    <ul>
                                                                        <li>Automatic monthly prize entry.</li>
                                                                        <li>Share up 50 portfolio gallery photos.</li>
                                                                        <li>Queue your photos up to four weeks ahead.</li>
                                                                        <li>View Camera Metadata and Settings </li>
                                                                        <li>Get Invites to all Monthly Townhalls</li>
                                                                        <li>Access to our Participating Member directory.</li>
                                                                        <li>Get a Participating Member badge.</li>
                                                                        <li>Post on your Followers' feeds (soon)</li>
                                                                        <li>See the "Ken Loves You" Video 🤪</li>
                                                                        <li>Help keep this project going!</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="price_table_btn">


                                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I want to Participate"} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* queue manager */}
                                <div className="modal_content_block queue_manager">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Queue Manager</h2>
                                                    <h4 className="subtitle">Hunt Your Photos While You Sleep </h4>
                                                    <div className="desc">Queue photos up to four weeks in advance so that <br />
                                                        whatever timezone you're in, you maximize each weekly hunt.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall_queue_manager.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I want to Participate"} />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* member directory */}
                                <div className="modal_content_block member_directory">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Participating Member Directory</h2>
                                                    <h4 className="subtitle">More eyeballs. Less Noise. </h4>
                                                    <div className="desc">Be included in the Participating Member directory for exclusive sharing
                                                        and connection with other Participating Members.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/member_directory.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I want to Participate"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* upvote giveaway */}
                                <div className="modal_content_block upvote_giveaway">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Be Automatically Included in
                                                        all Monthly Upvote Giveaways</h2>
                                                    <h4 className="subtitle">Automatically entered to win amazing gear. </h4>
                                                    <div className="desc">Participating Members are eligible to win cameras and gear.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/new-giveaway/image_1717156108034_1.jpg" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">

                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Upgrade Now"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* participating member */}
                                <div className="modal_content_block participating_member">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">"Hey, tell me about this Participating Membership, Ken."</h2>
                                                    <div className="desc">I’m kinda (definitely) a visual person. So, if you’re like me and you’d like to hear about what this is rather than read about it, this video is for you. Roll tape…</div>
                                                </div>
                                                <div className="youtube_video" style={{ width: "100%", maxWidth: '1030px', margin: 'auto', }}>
                                                    {/* <div className="video_inner">
                                                        <iframe src="https://www.youtube.com/embed/ZH2_hhsvu8U" title="YouTube video player" frameBorder={0} controls={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                                    </div> */}
                                                    <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}><div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}><div className="wistia_embed wistia_async_xxlx79t311 seo=true videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}><div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}><img src="https://fast.wistia.com/embed/medias/xxlx79t311/swatch" style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>
                                                </div>



                                                <div className="modal_content_btn text-center">

                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I get it. Lemme in!"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* photo gallery */}
                                <div className="modal_content_block photo_gallery">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Boost your Club presence with a BIG personal gallery </h2>
                                                    <h4 className="subtitle">Show off up to 50 photos on your profile so Members can really see your stuff</h4>
                                                    <div className="desc">Because limits are for the birds.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall-photo-gallery.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">

                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Need"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* energy */}
                                <div className="modal_content_block energy">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Play a Role in Keeping the Lights <br /> on Around Here</h2>
                                                    <div className="desc">Your participation literally helps us buy lightbulbs! 💡</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall-role.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I love conserving energy"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* testimonial */}
                                <div className="modal_content_block modal_testimonial">
                                    <div className="container-fluid gx-0 gx-md-3">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Testimonials</h2>
                                                </div>
                                                <div className=''>
                                                    <Swiper
                                                        className="swiper-wrapper mySwiper modal_testimonial_carousel swiper-container"
                                                        spaceBetween={10}
                                                        slidesPerView={3}
                                                        // loop={true}
                                                        // pagination={{ clickable: true }}
                                                        // navigation={true}
                                                        // breakpoints={{
                                                        //     650: {
                                                        //         slidesPerView: 1,
                                                        //         spaceBetween: 30,
                                                        //     },
                                                        //     768: {
                                                        //         slidesPerView: 2,
                                                        //         spaceBetween: 30,
                                                        //     },
                                                        //     1024: {
                                                        //         slidesPerView: 3,
                                                        //         spaceBetween: 40,
                                                        //     },
                                                        // }}

                                                        breakpoints={{
                                                            991: {
                                                                slidesPerView: 3,
                                                                spaceBetween: 20,
                                                            },
                                                            767: {
                                                                slidesPerView: 2,
                                                                spaceBetween: 20,
                                                            },
                                                            0: {
                                                                slidesPerView: 1,
                                                                spaceBetween: 20,
                                                            }
                                                        }}

                                                    >
                                                        {testimonialUserData?.map((data, index) => (
                                                            <SwiperSlide key={index} className="swiper-slide modal_testimonial_item">
                                                                <div className="modal_testimonial_item_inner">
                                                                    <div className="quote_img">
                                                                        <img src="./assets/img/icons/quote-mark.svg" alt="Quote Mark" />
                                                                    </div>
                                                                    <div className="review_text" style={{ textAlign: 'start' }}>
                                                                        “{data.quote}”
                                                                    </div>
                                                                    <div className="reviewer_author">
                                                                        <div className="reviewer_img">
                                                                            <img src={data.profile_pic} alt="Reviewer" />
                                                                        </div>
                                                                        <div className="reviewer_content" style={{ textAlign: 'start' }}>
                                                                            <h5 className="name">{data.name}</h5>
                                                                            <p className="designation">{data.state}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I get it. Lemme in!"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sort */}
                                <div className="modal_content_block sort">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">And, there's more... </h2>
                                                    <div className="desc">Your photos are sorted exclusively for all to more easily see.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall-participating-members.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Need"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* global membership */}
                                <div className="modal_content_block global_membership">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Receive invites to our monthly<br />
                                                        Club Member online town hall events</h2>
                                                    <div className="desc">Join Ken for guest speakers, photo reviews, featured Member intros, an open Q&A session and more.</div>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall-townhalls.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">


                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Dial me in!"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* video */}
                                <div className="modal_content_block video">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Get access to the "Ken Loves You" video.</h2>
                                                    <div className="desc">If nothing else on this page gets you excited, maybe this one will? 🤪</div>
                                                </div>
                                                <div onClick={() => handleButtonClick(
                                                    ActiveTabValue === "monthly"
                                                        ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY
                                                        : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY
                                                )} className="youtube_video" style={{ width: "100%", maxWidth: '1030px', margin: 'auto', cursor: 'pointer' }}>
                                                    {/* <div className="video_inner">
                                                        <iframe src="https://www.youtube.com/embed/ZH2_hhsvu8U" title="YouTube video player" frameBorder={0} controls={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                                    </div> */}

                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/image_1724747756113.jpg" alt />
                                                </div>

                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"OMG. I want that"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* activity feed */}
                                <div className="modal_content_block activity_feed">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">Share announcements, invites and more directly to your Followers' activity feed.
                                                    </h2>
                                                </div>
                                                <div className="modal_content_img">
                                                    <img src="https://bph051023a.s3.amazonaws.com/paywall/paywall-activity-feed.png" alt />
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"Join now"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* other stuff */}
                                <div className="modal_content_block other_stuff">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <div className="modal_section_title text-center">
                                                    <h2 className="title">And other stuff to get more exposure in the community</h2>
                                                </div>
                                                <div className="modal_content_btn text-center">
                                                    <PaymentBtn loading={loading} handleButtonClick={handleButtonClick} productKey={`${ActiveTabValue == "monthly" ? process.env.REACT_APP_STRIP_MONTHLY_PRODUCT_KEY : process.env.REACT_APP_STRIP_YEARLY_PRODUCT_KEY}`} buttonText={"I just want to support the project"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}

export default PayWallModal
